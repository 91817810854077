<template>
  <div class="game-item text-center position-relative">
    <i class="game-fav fa-star fa-xl text-warning" :class="[{'fa-regular': !isFavorite}, {'fa-solid': isFavorite}]" @click="toggleFavorite"></i>
    <!-- <router-link @click.native="goUrl(item._id)" :to="{name: 'PlayGame', params: { gameId: item._id}}" target="_blank">
      <img class="game-img" :src="item.img">
      <br /><span class="game-title">{{ item.name }}</span>
    </router-link> -->
    <div @click="play">
      <img class="game-img" :src="item.img">
      <br /><span class="game-title">{{ item.name }}</span>
    </div>

    <PlayModal :isShow="isShowModal" :game="item" :favorites="favorites" @close="isShowModal = false" />
  </div>
</template>
<script>
import GameService from '../../../services/GameService';
import PlayModal from './PlayModal.vue'	

export default {
  name: 'GameItem',
  components: {
    PlayModal
  },
  props: ['item', 'favorites'],
  data() {
    return {
      gameWindow: null,
      interval: null,
      isShowModal: false
    }
  },
  computed: {
    gameId() {
      return this.item?._id
    },
    isFavorite() {
      return this.favorites?.includes(this.gameId)
    }
  },
  methods: {
    // goUrl(gameId) {
    //   this.gameWindow = window.open('/play-game/' + gameId, 'PlayPopup')
    //   try {
    //     this.gameWindow.focus()
    //     clearInterval(this.interval)
    //     this.interval = setInterval(()=>{
    //       if (this.gameWindow.closed) {
    //         clearInterval(this.interval)
    //         this.$store.dispatch('reloadGameCredit')
    //       }
    //     }, 1000)
    //   }
    //   catch(e) {}
    // },
    toggleFavorite() {
      console.log('toggleFavorite', this.gameId)
      GameService.setFavorite({gameId: this.gameId})
      .then((response)=>{
        if(response.success) {
          console.log('toggleFavorite', response.data.favorites)
        }
      })
      .finally(()=>{
        this.$emit('reload')
      })
    },
    play() {
      this.isShowModal = true
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.interval)
  //   if(this.gameWindow) {
  //     this.gameWindow.close()
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.game-item {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #FFFFFF;

  .game-img {
    max-width: 100%;
  }

  .game-title {
    font-size: 90%;
  }

  a {
    text-decoration: none;
    color: #6c757d;
  }

  .game-fav {
    position: absolute;
    top: -12px;
    right: -22px;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
}

.game-item:hover {
  background-color: #FFE1A1;
  border: 1px solid #E5881E;

  .game-fav {
    opacity: 1;
  }
}
</style>