<template>
  <div class="play-lotto">

    <div style="position: relative;">

      <SetsRounds class="round-group mb-3" />

      <div v-if="groups.length" class="round-group mb-3" v-for="(group, groupId) in groups" :key="groupId">
        <h5 class="mb-0 text-pp">{{group.groupTitle}}</h5>
        <div class="row rounds no-gutters">
          <div class="col-md-4 mb-2" v-for="(round) in group.rounds" :key="round._id">
            <Round :data="round" @reload="getRounds" />
          </div>
        </div>
      </div>
      <div v-if="!groups.length && !isLoading" class="alert alert-pp border-pp">
        ยังไม่มีงวดเปิดรับแทง
      </div>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EEEEEE"
        :height="60"
        :width="60"
        color="#51CE8D"
        loader="dots"
      />
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import _ from 'lodash'
import cAlert from '@/helpers/alert'
import LotteryService from '@/services/LotteryService'
import Round from './PlayLotto/components/Round'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import moment from '@/helpers/moment'
import SetsRounds from './lottoset/components/SetsRounds'

export default {
  name: 'PlayLotto',
  components: {
    Round,
    SetsRounds,
    Loading
  },
  data() {
    return {
      data: this.$store.state.rounds,
      isLoading: false
    }
  },
  computed: {
    groups() {
      if(!this.data)
        return []

      return _.orderBy(this.data.groups, ['sort'], ['asc'])
      .map((group)=>{
        const rounds = this.data.rounds.filter((round)=>{
          return group._id === round.groupId
        })

        group.rounds = _.orderBy(rounds, (round)=>{
          const diff = moment(round.roundDate.close).diff(moment(), 's')
          if(diff > 0) {
            return diff
          }else{
            return 10000000 + diff
          }
        }, ['asc'])
        return group
      })
      .filter((group)=>{
        return group.rounds.length > 0
      })
    }
  },
  methods: {
    getRounds(silent=false) {
      this.isLoading = !silent
      LotteryService.getRounds()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$store.commit('updateRounds', this.data)
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        if(!silent) {
          cAlert({
            ...e,
            title: 'ผิดพลาด!',
            text: 'กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      })
      .finally(()=>{
        console.log('finally')
        this.isLoading = false
      })
    },
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group?.groupTitle
      }else{
        return groupId
      }
    },
    closeAlertMessage() {
      this.$store.commit('updateShowAlertMessageStatus', true)
    }
  },
  mounted() {
    this.getRounds(this.data?true:false)
  }
}
</script>
<style lang="scss" scoped>
.play-lotto {
  min-height: 800px;

  .round-group {
    .rounds {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }
  }
}
</style>
