<template>
  <div>
    <div class="container">
      <LottoMenu class="py-2" />
    </div>

    <div class="c-body container">
      <main class="c-main p-2">
        <CContainer class="p-2">
          <router-view :key="$route.path"></router-view>
        </CContainer>
      </main>
    </div>
  </div>
</template>
<script>
import LottoMenu from './LottoMenu'

export default {
  name: 'TheLottoContainer',
  components: {
    LottoMenu
  }
}
</script>
<style>
.c-main {
  background-color: #FFFFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #00A94F;
  border-bottom: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>