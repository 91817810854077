<template>
  <div class="pb-5">

    <!-- <MainMenu class="mb-3" /> -->

    <h4>สวัสดี! <span class="text-pp">{{ UserInfo.firstName }} {{ UserInfo.lastName }}</span></h4>
    <div class="row">
      <div class="col-md-6">
        <div class="card border-success">
          <div class="card-body alert-success">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h3 class="mb-0">ยอดคงเหลือ</h3>
              </div>
              <h3
                class="mb-0"
                :class="[{'text-danger': Balance<0}, {'text-success': Balance>0}]"
              >{{Balance | amountFormat(2, '0.00')}}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card border-info">
          <div class="card-body alert-info">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h3 class="mb-0">รอผลเดิมพัน</h3>
              </div>
              <h3 class="mb-0 text-info">{{ waitAmount | amountFormat(2, '0.00') }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <News />
      </div>
      <div class="col-md-12">
        <h5 class="text-pp">ผลหวยล่าสุด</h5>
        <table class="table table-pp table-bordered table-hover">
          <thead>
            <tr>
              <th colspan="2">หวย</th>
              <th>งวด</th>
              <th>3 ตัวบน</th>
              <th>2 ตัวล่าง</th>
              <th>สถานะ</th>
            </tr>
          </thead>
          <tbody v-for="group in groups" :key="group.group._id" v-if="group.items.length">
            <tr>
              <td colspan="8" class="alert-warning py-1">{{group.group.groupTitle}}</td>
            </tr>
            <tr v-for="item in group.items" :key="item._id">
              <td width="70" class="p-1">
                <img :src="imageIcon(item)" style="width: 60px;">
              </td>
              <td class="px-1 py-0">{{item.market.marketTitle}}</td>
              <td class="text-center px-1 py-1">{{item.roundDate.date | dispDateTime("DD-MM-YYYY")}}</td>
              <td v-if="item.market.marketType === 'single'" class="text-center px-1 py-0">
                <span class="badge fullsize badge-info">{{ item | result('threeNumberTop') }}</span>
              </td>
              <td v-if="item.market.marketType === 'single'" class="text-center px-1 py-0">
                <span class="badge fullsize badge-info">{{ item | result('twoNumberBottom') }}</span>
              </td>
              <td v-if="item.market.marketType === 'lottoset'" colspan="2" class="text-center px-1 py-0">{{ item | result('fourNumber') }}</td>
              <td class="text-center px-1 py-0">
                <span class="badge" :class="item.roundStatus.badge">{{item.roundStatus.text}}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!groups.length">
            <tr>
              <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import _ from 'lodash'
import News from '@/views/news/components/News'

export default {
  name: 'Home',
  components: {
    News
  },
  data() {
    return {
      rounds: null,
      waitAmount: 0
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    Balance() {
      return this.$store.state.account.balance
    },
    marketGroups() {
      return this.$store.state.marketGroups
    },
    markets() {
      return this.$store.state.Merkets
    },
    groups() {
      if(!this.rounds)
        return []

      const items = _.orderBy(this.rounds.map((item)=>{

        const roundStatus = {
          badge: 'badge-secondary',
          text: ''
        }

        if(item.status === 'Open') {
          roundStatus.badge = 'badge-info'
          roundStatus.text = 'รอออกผล'
        }else
        if(item.status === 'Close') {
          roundStatus.badge = 'badge-info'
          roundStatus.text = 'รอออกผล'
        }else
        if(item.status === 'Cancel') {
          roundStatus.badge = 'badge-danger'
          roundStatus.text = 'ยกเลิก'
        }else
        if(item.status === 'Refund') {
          roundStatus.badge = 'badge-primary'
          roundStatus.text = 'คืนเงิน'
        }else
        if(item.status === 'Complete') {
          roundStatus.badge = 'badge-primary'
          roundStatus.text = 'กำลังออกผล'
        }else
        if(item.status === 'Paid') {
          roundStatus.badge = 'badge-success'
          roundStatus.text = 'จ่ายเงินแล้ว'
        }

        item.roundStatus = roundStatus

        return item
      }), ['roundDate.close'], ['asc'])

      const groups = _.groupBy(items, (round)=>{
        return round.groupId
      })

      const rounds = []
      for(const groupId in groups) {
        rounds.push({
          group: this.marketGroups.find((g)=>{
            return g._id === groupId
          }),
          items: groups[groupId]
        })
      }

      return _.orderBy(rounds, ['group.sort'], ['asc'])
    }
  },
  methods: {
    getLastResults() {
      ReportService.getLastResults()
      .then((response)=>{
        if(response.success) {
          this.rounds = response.data
        }
      })
    },
    imageIcon(item) {
      return item?.market?.imageIcon
    },
    getWaitAmount() {
      ReportService.getWaitAmount()
      .then((response)=>{
        if(response.success) {
          this.waitAmount = response.data
        }
      })
    }
  },
  mounted() {
    this.getLastResults()
    this.getWaitAmount()
  },
  filters: {
    result(market, type) {

      if(market.status === 'Cancel') {
        return 'ยกเลิก'
      }else
      if(market.status === 'Refund') {
        return 'คืนเงิน'
      }

      if(!market?.results[type])
        return ''

      return market.results[type].join('-')
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        vertical-align: middle;
        line-height: 1.2;

        .badge {
          font-size: 95%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
