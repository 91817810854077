<template>
  <div class="game-items position-relative py-3">
    
    <nav aria-label="breadcrumb">
      <ul class="breadcrumb py-1">
        <li class="breadcrumb-item active" aria-current="page">{{ tagName }} :: {{ brandName }}</li>
      </ul>
    </nav>

    <div class="row no-gutters">
      <div v-for="item in items" :key="item._id" class="col-4 col-md-2">
        <GameItem :item="item" :favorites="favorites" @reload="getGames" />
      </div>
    </div>

    <div v-if="!isLoading && !items.length" class="alert alert-warning mb-0 border-warning">ไม่มีรายการเกม</div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import GameItem from './components/GameItem'	

export default {
  name: 'GameByTag',
  components: {
    GameItem
  },
  data() {
    return {
      data: null,
      isLoading: true
    }
  },
  computed: {
    tagId() {
      return this.$route.params.tagId
    },
    brandId() {
      return this.$route.params.brandId
    },
    items() {
      return this.data?.items || []
    },
    brand() {
      return this.data?.brand || {}
    },
    brandName() {
      return this.brand?.name || ''
    },
    tag() {
      return this.data?.tag || {}
    },
    tagName() {
      return this.tag?.name || ''
    },
    favorites() {
      if(!this.data?.favorites)
        return []

      return this.data.favorites
    }
  },
  methods: {
    getGames() {
      this.isLoading = true
      GameService.getGameByTag(this.tagId, this.brandId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'แจ้งเตือน!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getGames()
  }
}
</script>
<style lang="scss" scoped>
.game-items {
  min-height: 400px;

  .cat-link {
    cursor: pointer;
  }

  .cat-link:hover {
    text-decoration: underline;
  }
}
</style>