<template>
  <div class="game-cats">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link :to="{name: 'BrandByCat', params: { catName: 'all' }}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div class="mb-2"><i class="fa-regular fa-place-of-worship fa-xl"></i></div>
          <div>ค่ายเกม</div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{name: 'BrandByCat', params: { catName: 'slot' }}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div class="mb-2"><i class="fa-regular fa-slot-machine fa-xl"></i></div>
          <div>สล็อต</div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{name: 'BrandByCat', params: { catName: 'casino' }}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div class="mb-2"><i class="fa-regular fa-cards fa-xl"></i></div>
          <div>คาสิโน</div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{name: 'BrandByCat', params: { catName: 'sport' }}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div class="mb-2"><i class="fa-regular fa-futbol fa-xl"></i></div>
          <div>กีฬา</div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{name: 'BrandByCat', params: { catName: 'fishing' }}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div class="mb-2"><i class="fa-regular fa-fish fa-xl"></i></div>
          <div>ยิงปลา</div>
        </router-link>
      </li>

      <li v-for="item in tags" :key="item._id" class="nav-item">
        <router-link :to="{name: 'BrandByTag', params: { tagId: item._id }}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div v-if="item.icon" class="mb-2"><i :class="item.icon"></i></div>
          <div>{{item.name}}</div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{name: 'GameFavorites'}" class="cat-item nav-link text-center" active-class="active text-primary">
          <div class="mb-2"><i class="fa-solid fa-star fa-xl text-warning fa-flip"></i></div>
          <div>เกมโปรด</div>
        </router-link>
      </li>
    </ul>

    <transition name="fade" mode="out-in">
      <router-view :key="$route.path"></router-view>
    </transition>
  </div>
</template>
<script>
import GameService from '@/services/GameService'

export default {
  name: 'GameDashboard',
  data() {
    return {
      dataTags: null,
      tagItem: null
    }
  },
  computed: {
    tags() {
      if(!this.dataTags)
        return []

      return this.dataTags
    }
  },
  methods: {
    getTags() {
      GameService.getTags()
      .then((response)=>{
        if(response.success) {
          this.dataTags = response.data

          if(this.$route.params.tagId) {
            this.tagItem = this.dataTags.find((item)=>item._id===this.$route.params.tagId)
            this.category = null
            this.page = 'tag'
          }
        }
      })
    }
  },
  mounted() {
    this.getTags()
  }
}
</script>
<style lang="scss">
.game-cats {
  padding: 15px;

  .cat-item {
    cursor: pointer;
    width: 100px;
    padding: 15px 0;
    margin: 0 10px;
    color: #666;
  }
}
</style>
