<template>
  <b-modal 
    id="play-modal"	
    ref="play-modal" 
    size="lg" 
    no-close-on-backdrop
    hide-footer
    hide-header
    @show="createSession"
  >
    <button type="button" class="close-btn" @click="close()"><i class="fas fa-times"></i></button>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#212121"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />

    <iframe 
      v-if="gameUrl"
      :src="gameUrl" 
      class="modal-iframe"
      frameborder="0" 
      allowfullscreen 
      allow="autoplay; fullscreen" 
      ref="iframe"></iframe>
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import Swal from 'sweetalert2';

export default {
  name: 'PlayModal',
  props: ['isShow', 'game', 'favorites'],
  data() {
    return {
      isLoading: false,
      gameUrl: null,
      startTime: null
    }
  },
  computed: {
    gameId() {
      return this.game?._id
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['play-modal'].show()
      }else{
        this.$refs['play-modal'].hide()
      }
    }
  },
  methods: {
    createSession() {
      this.isLoading = true
      GameService.playGame(this.gameId)
        .then((response)=>{
          if(response.success) {
            this.gameUrl = response.data
            this.startTime = new Date()
          }
        })
        .catch((e)=>{
          cAlert({
            ...e,
            title: 'ผิดพลาด!',
            text: 'กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          }, ()=>{
            this.close()
          })
        })
        .finally(()=>{
          this.isLoading = false
        })
    },
    close() {

      const endTime = new Date()
      const diff = (endTime - this.startTime) / 1000

      if(this.startTime && diff > 180 && !this.favorites?.includes(this.gameId)) {
        Swal.fire({
          title: 'เกมโปรด!',
          text: 'คุณต้องการเพิ่มเกมนี้เป็นเกมโปรดหรือไม่?',
          icon: 'info',
          confirmButtonText: 'ตกลง',
          showCancelButton: true,
          cancelButtonText: 'ไม่ต้องการ',
        })
        .then((result) => {
          if (result.isConfirmed) {
            GameService.setFavorite({gameId: this.gameId})
          }
        })
        .finally(()=>{
          this.$refs['play-modal'].hide()
        })
      }else{
        this.$refs['play-modal'].hide()
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#play-modal {

  .modal-dialog {
    width: 98vw; /* ความกว้างเท่ากับ viewport */
    height: 98vh; /* ความสูงเท่ากับ viewport */
    margin: 1vh auto; /* ไม่มี margin รอบๆ */
    max-width: none; /* ลบข้อจำกัดของ max-width ของ Bootstrap */
  }

  .modal-content {
    height: 100%; /* ให้ modal-content มีความสูงเต็ม modal-dialog */
    border-radius: 8px; /* ลบมุมโค้งเพื่อให้พอดีกับขอบหน้าจอ */
    
    .modal-body {
      height: 100%; /* ให้ modal-body มีความสูงเต็ม modal-content */
      padding: 0; /* ลบ padding ที่มากับ Bootstrap */

      .close-btn {
        position: absolute; /* ตำแหน่งเป็น absolute */
        top: -5px; /* จากด้านบน 10px */
        right: -5px; /* จากด้านขวา 10px */
        background-color: #C63D2F; /* สีพื้นหลังของปุ่ม */
        border: none; /* ลบเส้นขอบ */
        border-radius: 50%; /* ทำให้มีรูปร่างเป็นวงกลม */
        padding: 5px 10px; /* ระยะห่างของ icon จากขอบปุ่ม */
        cursor: pointer; /* เปลี่ยน cursor เป็น pointer */
        z-index: 1; /* ให้ปุ่มอยู่ด้านบนสุด */

        i {
          color: #FFFFFF; /* สีของ icon */
        }
      }

      .modal-iframe {
        position: relative; /* ตำแหน่งเป็น relative */
        width: 100%; /* ความกว้างเต็มของ modal-body */
        height: 100%; /* ความสูงเต็มของ modal-body */
        border: none; /* ลบเส้นขอบ */
      }
    }
  }
}
</style>