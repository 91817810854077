<template>
  <CHeader class="d-flex">
    <div class="container">
      <div class="d-flex align-items-center">
        <ul class="c-header-nav mr-auto">
          <!-- <div class="app-logo">
            <router-link to="/">
              <img src="@/assets/header2.png">
            </router-link>
          </div> -->

          <li class="top-menu-item nav-item mr-2">
            <router-link :to="{name: 'Home'}" active-class="top-menu-active" class="nav-link"><i class="fa-regular fa-pool-8-ball fa-xs"></i> หวย</router-link>
          </li>
          <li class="top-menu-item nav-item">
            <router-link :to="{name: 'Game'}" active-class="top-menu-active" class="nav-link"><i class="fa-regular fa-gamepad-modern fa-xs"></i> เกม</router-link>
          </li>
        </ul>
        <CHeaderNav class="ml-auto">
          <TheHeaderTime class="mr-4" />
          <TheHeaderBalance class="mr-2" />
          <TheHeaderDropdownAccnt/>
        </CHeaderNav>
      </div>
    </div>
  </CHeader>
</template>
<script>
import TheHeaderTime from './TheHeaderTime'
import TheHeaderBalance from './TheHeaderBalance'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderTime,
    TheHeaderBalance,
    TheHeaderDropdownAccnt
  },
  methods: {
    logout() {
      if(this.$store.state.isProcessTicket) {
        Swal.fire({
          text: 'กรุณาอย่าเปลี่ยนหน้าขณะกำลังส่งโพย',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }else{
        Auth.logout()
      }
    }
  }
}
</script>
<style lang="scss">
.c-header {
  background-color: #00A94F;

  .app-logo {
    img {
      height: 45px;
    }
  }

  .c-header-nav-link {
    color: #FFFFFF !important;
  }

  .top-menu-item {
    a {
      color: #FFFFFF;
      font-size: 1.2rem;
      text-decoration: none;
      border-radius: 5px;
      display: inline-block;
    }

    a:hover {
      color: #FFFFFF;
      background-color: #005A2A;
    }
  }
}
</style>
