<template>
  <div class="game-brands position-relative py-3">
    <div v-if="items.length" class="mb-0">
      <div class="row">
        <div v-for="item in items" :key="item._id" class="col-md-2 my-2">
          <router-link :to="{name: 'GameByTag', params: { tagId: tagId, brandId: item._id }}" class="brand-item border-warning rounded text-center py-3">
            <img v-if="item.image" :src="item.image" alt="">
            <span v-else>{{item.name}}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else-if="!items.length && !isLoading" class="alert alert-warning mb-0 border-warning">
      ไม่มีข้อมูล
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'

export default {
  name: 'BrandByTag',
  data() {
    return {
      data: null,
      isLoading: true
    }
  },
  computed: {
    tagId() {
      return this.$route.params.tagId
    },
    items() {
      return this.data?.brands || []
    }
  },
  methods: {
    getBrands() {
      this.isLoading = true
      GameService.getBrandByTag(this.tagId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'แจ้งเตือน!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getBrands()
  }
}
</script>
<style lang="scss">
.game-brands {
  min-height: 400px;

  .brand-item {
    cursor: pointer;
    border: 0 !important;
    border-radius: 40px !important;
    display: block;
    color: #666666;

    img {
      width: 100%;;
    }
  }

  .brand-item:hover {
    // color: #FFFFFF;
    background-color: #E9E9E9;
  }
}
</style>